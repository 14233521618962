
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import user from '@/services/user'
import BusifyLogo from '@/components/BusifyLogo.vue'
import { validateRules, validationRules } from '@/utils/rules'

@Component({
  components: {
    BusifyLogo,
  },
})
export default class SetPassword extends Vue {
  @Prop({ required: true }) hash!: string

  @Watch('newPassword')
  handleNewPasswordChanged(): void {
    this.error = false
  }

  @Watch('confirmPassword')
  handleConfirmPasswordChanged(): void {
    this.error = false
  }

  newPassword = ''
  confirmPassword = ''
  loading = false
  error = false
  rules = validationRules

  passwordsMatch(password: string): boolean {
    return password === this.newPassword
  }

  cancel(): void {
    this.$router.push({ name: 'login' })
  }

  async submit(): Promise<void> {
    if (!(await validateRules(this))) {
      return
    }
    this.loading = true
    if (this.newPassword !== this.confirmPassword) {
      this.loading = false
      this.error = true
      return
    }

    try {
      await user.setPasswordWithHash(this.hash, this.newPassword)
    } catch (e) {
      this.loading = false
      // eslint-disable-next-line no-console
      console.error(e)
      return
    }
    this.newPassword = ''
    this.confirmPassword = ''
    this.loading = false
    this.$router.push({ name: 'login' })
  }
}
